.action-bar {
    width: 100%;
    height: 30px;
    background-color: #f8f8f8;
    border: 1px solid #babfc6;
    border-bottom: none;
}

.ag-theme-alpine .ag-row {
    font-size: 13px;
    font-family: 'Noto Sans', sans-serif;
}
