.add-tolerance-button {
    position: sticky;
    top: 50%;
}

.tolerance-button-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.tolerance-button {
    margin: 0rem 0.25rem;
}

.tolerance-line-break {
    margin-bottom: 1.25rem;
}

.ag-group-value {
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    height: 40px;
}
