body,
html {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans', sans-serif;
    font-size: 14px;
    color: #333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Noto Sans', sans-serif;
}

/* tweak the styles of the form fields so that the mobile browser does not zoom in on them on focus. */
select.form-control,
textarea.form-control,
input.form-control {
    font-size: 16px;
}
/* width of the input type file to prevent the page on mobile from overflowing and adding a scrollbar */
input[type='file'] {
    width: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.card-body {
    padding: 5px;
}

.card-text {
    text-align: left;
    margin: 5px 5px 5px 5px;
}

.card-body .card-header {
    margin-bottom: 5px;
}

.caution-text {
    color: red;
}

form .row {
    margin-bottom: 0;
}

.formGroupHeading {
    color: #104c8f;
    font-size: medium;
    font-weight: bold;
    font-family: sans-serif;
    /*display: inline;*/
}

form label {
    color: #007cbb;
    font-size: small;
    font-weight: bold;
    font-family: sans-serif;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-right: 0 !important;
    /*display: inline;*/
}

.form-control-plaintext {
    font-size: small;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

table tbody {
    font-size: small;
}

.appPage {
    overflow-y: auto !important;
    height: 88vh;
}
