@media all and (min-width: 480px) {
    .Settings {
        padding: 60px 0;
        margin: 0 auto;
        max-width: 480px;
    }

    .Settings .LoaderButton {
        margin-bottom: 15px;
    }
}
